import { Outlet } from "react-router-dom"
import Header from "../Header/Header";


const Home=()=>{
    return (
        <div className="flex">
        <Header />
        <Outlet/>
            </div>
    )
};
export default Home;
import { Outlet } from "react-router-dom"
import Assessment from "./AssessmentList";

const page=()=>{
    return(
        <>
       
        <Outlet/>
        </>
    )
};
export default page;
import useStore from "../../store/useStore";
import { useQuery } from "react-query";
import Loading from "../../common/Loading";
import ErrorPage from "../../common/ErrorPage";
import candidateListApi from "../../apis/candidatelist";
import { useMemo } from "react";
import moment from "moment/moment";
import Header from "../Header/Header";
import { useLocation,useNavigate } from "react-router-dom";

const CandidateList=()=>{
  const location = useLocation();
  const { examId,recentype ,title,icon} = location.state || {};
   const token=useStore((state)=>state.token);
   const navigate=useNavigate();
   const fetchCandidatelist=async()=>{
    const reposnse=await candidateListApi.show(token);
    return reposnse.data;
   }
   const handleBackClick=()=>{
    navigate('/home/assessments');
  }
   const {data,isLoading,isError}=useQuery({
    queryKey:['candidatelist'],
    queryFn:fetchCandidatelist,
   })
   const { assignedArray, completedArray, Shortlisted } = useMemo(() => {
    if (!data) return { assignedArray: [], completedArray: [], Shortlisted: [] };
    return {
      assignedArray: data.filter((elem) => elem.examStatus === 'enrolled'),
      completedArray: data.filter((elem) => elem.examStatus === 'ended'),
      Shortlisted: data.filter((elem) => elem.recommendStatus === 'shortlisted'),
    };
  }, [data]);
   if(isLoading) return <Loading />
   if(isError) return <ErrorPage/>
 

  
    return (
     
    <div className="flex mr-auto  ml-auto">
        <div className="mt-6">
  <div className="flex w-full flex-col items-center justify-center gap-6">
    {/* <!-- header --> */}
    <div className="flex items-center gap-4">
      {icon && <div className="mt-1" onClick={handleBackClick}>
        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M19.002 12H5.00195" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M12.002 19L5.00195 12L12.002 5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </div>}
      <div className="text-2xl font-semibold">Candidate List</div>
      {title && <div className="flex items-center gap-2">
      <div className="mt-1">
        <svg width="6" height="4" viewBox="0 0 8 4" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0.345953 3.28V0.592H7.96195V3.28H0.345953Z" fill="black" />
        </svg>
      </div>
      <div className="text-2xl font-semibold">{title}</div>
    </div>}
    </div>
    {/* <!-- benchmarks --> */}
    <div className=" bg-whisperGray" style={{width:'800px'}}>
      <div className="flex w-full items-center justify-between px-4 py-2">
        <div className="text-sm">{recentype}</div>
        <div>
          <svg width="16" height="16" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.00195 7.5L10.002 12.5L15.002 7.5" stroke="#191C45" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </div>
      </div>
    </div>
    {/* <!-- filter parts --> */}
    <div className="flex w-full items-center justify-between px-4 my-4">
      {/* <!-- left part --> */}
      <div className="flex items-center gap-4">
        <div className="flex items-center gap-2 rounded-full border border-black px-4 py-2  bg-grayColor">
          <div className="text-sm">All Filters</div>
          <div>
            <svg width="15" height="15" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_5876_25593)">
                <path d="M18.3366 2.5H1.66992L8.33659 10.3833V15.8333L11.6699 17.5V10.3833L18.3366 2.5Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              </g>
              <defs>
                <clipPath id="clip0_5876_25593">
                  <rect width="20" height="20" fill="white" transform="translate(0.00292969)" />
                </clipPath>
              </defs>
            </svg>
          </div>
        </div>
        <div className="flex items-center gap-2 rounded-full border border-black px-4 py-2">
          <div className="text-sm">Role</div>
          <div>
            <svg width="15" height="15" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M5.00293 7.5L10.0029 12.5L15.0029 7.5" stroke="#191C45" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </div>
        </div>
        <div className="flex items-center gap-2 rounded-full border border-black px-4 py-2  bg-grayColor">
          <div className="text-sm">All</div>
          <div>
            <svg width="15" height="15" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12.0029 4L4.00293 12" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M4.00293 4L12.0029 12" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </div>
        </div>
        <div className="rounded-full border border-black px-4 py-2 text-sm">Completed</div>
        <div className="rounded-full border border-black px-4 py-2 text-sm">Rejected</div>
      </div>
      {/* <!-- right part --> */}
      <div className="flex items-center gap-4">
        <div className="flex items-center gap-2 rounded-md border border-black px-4 py-2">
          <div>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7.33333 12.6667C10.2789 12.6667 12.6667 10.2789 12.6667 7.33333C12.6667 4.38781 10.2789 2 7.33333 2C4.38781 2 2 4.38781 2 7.33333C2 10.2789 4.38781 12.6667 7.33333 12.6667Z" stroke="#808080" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M14.0001 14.0001L11.1001 11.1001" stroke="#808080" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </div>
          <input type="text" className="text-sm text-grayish outline-none" placeholder="Search" />
        </div>
        <div className="flex items-center gap-2 rounded-full border border-black bg-sunFlowerYellow px-4 py-2">
          <div className="text-sm">Download List</div>
          <div>
            <svg width="18" height="18" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M17.5 12.5V15.8333C17.5 16.2754 17.3244 16.6993 17.0118 17.0118C16.6993 17.3244 16.2754 17.5 15.8333 17.5H4.16667C3.72464 17.5 3.30072 17.3244 2.98816 17.0118C2.67559 16.6993 2.5 16.2754 2.5 15.8333V12.5" stroke="#191C45" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M5.8335 8.3335L10.0002 12.5002L14.1668 8.3335" stroke="#191C45" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M10 12.5V2.5" stroke="#191C45" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </div>
        </div>
      </div>
    </div>
    {/* <!-- three column assessment --> */}
    <div className="grid w-full grid-cols-3 gap-8 p-4">
      {/* <!-- assign  --> */}
      <div className="bg-whisperGray px-2 py-2 rounded-md ">
        {/* <!-- header --> */}
        <div className="flex items-center justify-between">
          <div className="text-xl font-semibold">Assigned <span>({assignedArray.length})</span></div>
          <div className="flex items-center gap-2 rounded-full bg-sunFlowerYellow px-4 py-2">
            <div className="text-md">Assign</div>
            <div>
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_6233_21635)">
                  <path d="M13.3356 17.5V15.8333C13.3356 14.9493 12.9844 14.1014 12.3593 13.4763C11.7342 12.8512 10.8863 12.5 10.0023 12.5H5.00228C4.11822 12.5 3.27038 12.8512 2.64526 13.4763C2.02013 14.1014 1.66895 14.9493 1.66895 15.8333V17.5" stroke="#191C45" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M7.50228 9.16667C9.34323 9.16667 10.8356 7.67428 10.8356 5.83333C10.8356 3.99238 9.34323 2.5 7.50228 2.5C5.66133 2.5 4.16895 3.99238 4.16895 5.83333C4.16895 7.67428 5.66133 9.16667 7.50228 9.16667Z" stroke="#191C45" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M15.835 6.6665V11.6665" stroke="#191C45" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M18.335 9.1665H13.335" stroke="#191C45" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </g>
                <defs>
                  <clipPath id="clip0_6233_21635">
                    <rect width="20" height="20" fill="white" transform="translate(0.00195312)" />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
        </div>
        <hr className="my-4 border-black" />
        {/* <!-- body --> */}
        {assignedArray.map(elem=>(
        <div  key={elem.user.id} className="flex flex-col justify-center gap-4 mt-4">
          <div className="flex flex-col justify-center gap-2">
            <div className="flex items-center justify-between">
              <div>{elem.user.name}</div>
              <div>{elem.recommendStatus}</div>
            </div>
            <div className="flex items-center justify-between text-sm text-grayish">
              <div className="text-sm text-grayish">{elem.user.email}</div>
              <div className="text-sm text-grayish">{moment (elem.user.createdAt).format("Do MMM  YYYY")}</div>
            </div>
            <hr className="mt-2 border-black" />
          </div>
        </div>
        ))}
      </div>
       
      {/* <!-- completed --> */}
      <div className="bg-yellowShade p-4 rounded-md">
        <div className="text-xl font-semibold">Completed <span>({completedArray.length})</span></div>
        <hr className="my-4 border-black" />

        { completedArray.map(elem=>{
           const mean = elem.exam?.benchMarkScore?.Mean ?? 0;
           const sde1 = elem.exam?.benchMarkScore?.SDE1 ?? 0;
           const sde2 = elem.exam?.benchMarkScore?.SDE2 ?? 0;
           const percentage=(num1,num2,num3)=>{
            return Math.round(((num1+num2+num3)/300)*100);
           }
          
         return  (
          <div className="flex flex-col justify-center gap-2 mt-4" key={elem.id}>
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-4">
                <div className="text-sm flex flex-wrap">{elem.user.name}</div>
                <button className="flex items-center gap-2 rounded-full border border-forestGreen px-4 py-1">
                  <div>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_6233_21709)">
                        <path d="M4.66846 6.6665V14.6665" stroke="#3E721E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M10.0021 3.92016L9.33545 6.66683H13.2221C13.4291 6.66683 13.6333 6.71502 13.8184 6.80759C14.0035 6.90016 14.1646 7.03457 14.2888 7.20016C14.413 7.36576 14.4969 7.55799 14.5339 7.76165C14.571 7.9653 14.5601 8.17478 14.5021 8.3735L12.9488 13.7068C12.868 13.9838 12.6996 14.2271 12.4688 14.4002C12.238 14.5733 11.9573 14.6668 11.6688 14.6668H2.66878C2.31516 14.6668 1.97602 14.5264 1.72597 14.2763C1.47592 14.0263 1.33545 13.6871 1.33545 13.3335V8.00016C1.33545 7.64654 1.47592 7.3074 1.72597 7.05735C1.97602 6.80731 2.31516 6.66683 2.66878 6.66683H4.50878C4.75684 6.6667 4.99994 6.59737 5.21075 6.46664C5.42157 6.33592 5.59173 6.14897 5.70212 5.92683L8.00212 1.3335C8.3165 1.33739 8.62594 1.41228 8.90732 1.55256C9.1887 1.69284 9.43473 1.8949 9.62705 2.14363C9.81937 2.39236 9.95299 2.68134 10.0179 2.98896C10.0829 3.29659 10.0775 3.61492 10.0021 3.92016Z" stroke="#3E721E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                      </g>
                      <defs>
                        <clipPath id="clip0_6233_21709">
                          <rect width="16" height="16" fill="white" transform="translate(0.00195312)" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <div className="text-md font-semibold text-forestGreen">Recommended</div>
                </button>
              </div>
              <div className="flex items-center gap-2">
                <div>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M19.001 3H5.00098C3.89641 3 3.00098 3.89543 3.00098 5V19C3.00098 20.1046 3.89641 21 5.00098 21H19.001C20.1055 21 21.001 20.1046 21.001 19V5C21.001 3.89543 20.1055 3 19.001 3Z" fill="#3E721E" />
                    <path d="M9.00098 12L11.001 14L15.001 10" fill="#3E721E" />
                    <path d="M9.00098 12L11.001 14L15.001 10" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </div>
                <div>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M19.001 3H5.00098C3.89641 3 3.00098 3.89543 3.00098 5V19C3.00098 20.1046 3.89641 21 5.00098 21H19.001C20.1055 21 21.001 20.1046 21.001 19V5C21.001 3.89543 20.1055 3 19.001 3Z" fill="#E04C4C" />
                    <path d="M9.00098 9L15.001 15" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M15.001 9L9.00098 15" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </div>
              </div>
            </div>
            <div className="flex items-center justify-between text-sm text-grayish">
              <div className="flex items-center gap-2 text-sm text-grayish">
                <div>Kodiva Score:</div>
                <div>{Math.round(elem.marksScored)}</div>
              </div>
              <div className="flex items-center gap-2 text-sm text-grayish">
                <div>Benchmark:</div>
                <div>{percentage(mean,sde1,sde2)}%</div>
              </div>
            </div>
            <hr className="mt-2 border-black" />
          </div>
        )})}
      </div>
      {/* <!-- shortlisted --> */}
   
      <div className="bg-lightgreenColor p-4 rounded-md">
        <div className="text-xl font-semibold">Shortlisted<span>({Shortlisted.length})</span></div>
        <hr className="my-4 border-black" />
        {Shortlisted.map((elem)=>{
            const mean = elem.exam?.benchMarkScore?.Mean ?? 0;
            const sde1 = elem.exam?.benchMarkScore?.SDE1 ?? 0;
            const sde2 = elem.exam?.benchMarkScore?.SDE2 ?? 0;
            const percentage=(num1,num2,num3)=>{
             return Math.round(((num1+num2+num3)/300)*100);
            }
           
          return (
          <div className="flex flex-col justify-center gap-2 mt-4">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-4">
                <div className="text-xl">{elem.user.name}</div>
                <button className="flex items-center gap-2 rounded-full border border-forestGreen px-4 py-1">
                  <div>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_6233_21709)">
                        <path d="M4.66846 6.6665V14.6665" stroke="#3E721E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M10.0021 3.92016L9.33545 6.66683H13.2221C13.4291 6.66683 13.6333 6.71502 13.8184 6.80759C14.0035 6.90016 14.1646 7.03457 14.2888 7.20016C14.413 7.36576 14.4969 7.55799 14.5339 7.76165C14.571 7.9653 14.5601 8.17478 14.5021 8.3735L12.9488 13.7068C12.868 13.9838 12.6996 14.2271 12.4688 14.4002C12.238 14.5733 11.9573 14.6668 11.6688 14.6668H2.66878C2.31516 14.6668 1.97602 14.5264 1.72597 14.2763C1.47592 14.0263 1.33545 13.6871 1.33545 13.3335V8.00016C1.33545 7.64654 1.47592 7.3074 1.72597 7.05735C1.97602 6.80731 2.31516 6.66683 2.66878 6.66683H4.50878C4.75684 6.6667 4.99994 6.59737 5.21075 6.46664C5.42157 6.33592 5.59173 6.14897 5.70212 5.92683L8.00212 1.3335C8.3165 1.33739 8.62594 1.41228 8.90732 1.55256C9.1887 1.69284 9.43473 1.8949 9.62705 2.14363C9.81937 2.39236 9.95299 2.68134 10.0179 2.98896C10.0829 3.29659 10.0775 3.61492 10.0021 3.92016Z" stroke="#3E721E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                      </g>
                      <defs>
                        <clipPath id="clip0_6233_21709">
                          <rect width="16" height="16" fill="white" transform="translate(0.00195312)" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <div className="text-md font-semibold text-forestGreen">Recommended</div>
                </button>
              </div>
              <div className="flex items-center gap-2">
                <div>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M19 3H5C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3Z" fill="#CCCCCC" />
                    <path d="M9 12L11 14L15 10" fill="#CCCCCC" />
                    <path d="M9 12L11 14L15 10" stroke="#808080" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </div>
                <div>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M19.001 3H5.00098C3.89641 3 3.00098 3.89543 3.00098 5V19C3.00098 20.1046 3.89641 21 5.00098 21H19.001C20.1055 21 21.001 20.1046 21.001 19V5C21.001 3.89543 20.1055 3 19.001 3Z" fill="#E04C4C" />
                    <path d="M9.00098 9L15.001 15" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M15.001 9L9.00098 15" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </div>
              </div>
            </div>
            <div className="flex items-center justify-between text-sm text-grayish">
              <div className="flex items-center gap-2 text-sm text-grayish">
                <div>Kodiva Score:</div>
                <div>{Math.round(elem.marksScored)}</div>
              </div>
              <div className="flex items-center gap-2 text-sm text-grayish">
                <div>Benchmark:</div>
                <div>{percentage(mean,sde1,sde2)}%</div>
              </div>
            </div>
            <hr className="mt-2 border-black" />
          </div>
        
      )}
      )}

      </div>
    </div>
  </div>
</div>
</div>

    )
}
export default CandidateList;
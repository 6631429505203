
const Welcome=()=>{
    return (
        <div className="w-full   bg-white flex  flex-col justify-between shadow-lg  bg-cover bg-center text-white"
    style={{backgroundImage:"url('/images/welcomeBg.png')"}}
    >
      
      <div className=" min-h-[30vh] md:min-h-screen  flex  flex-col gap-10  justify-center items-center ">
       
      <div className="text-center">
        <h1 className=" font-bold text-4xl  py-4 ">
          Welcome to Kodiva.ai
        </h1>
        <p className="flex flex-wrap w-full px-10 text-xl ">
          Upskill, assess and apply to your dream jobs, all under one banner.
        </p>
        
        </div>
      </div>
    
    </div>
    )
};
export default Welcome;
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import responseInterceptors from "./apis/axios";
import Header from "./components/Header/Header";
import LoginPage from "./components/Login/LoginPage";
import { QueryClientProvider,QueryClient } from "react-query";
import ErrorPage from "./common/ErrorPage";
import AssignCandidate from "./components/AssignCandidate/AssignCandidate";
import RoleList from "./components/RoleList/Rolelist";
import Assessment from "./components/AssessmentList/index";
import AssessmentList from "./components/AssessmentList/AssessmentList";
import CandidateList from "./components/CandidateList/CandidateList";
import Dashboard from "./components/dashboard";
import Home from "./components/Home/home";
responseInterceptors();

const App=()=> {
  const queryClient=new QueryClient();

  return (
    <>
    <BrowserRouter>
    <QueryClientProvider client={queryClient}>
    
    {/* <Header /> */}
    <Routes>
    <Route path="/" element={<LoginPage />}/>

    <Route path="/home" element={<Home />}>
    <Route index element={<Dashboard />} />
   
    <Route path="roles" element={<RoleList />}/>
    <Route path='assessments' element={<Assessment />}>
    <Route index element={<AssessmentList/>}/>
    <Route path="assigncandidate" element={<AssignCandidate/>}/>
    <Route path="candidate" element={<CandidateList />} />
    </Route>
    
    <Route path="candidates" element={<CandidateList />} />
    </Route>
    <Route path="*" element={<ErrorPage />}/>
    </Routes>
  
    </QueryClientProvider>
    </BrowserRouter>
    </>
  );
}

export default App;

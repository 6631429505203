import { useState } from "react";
import { useMutation } from "react-query";
import authApi from "../../apis/auth";
import useStore from "../../store/useStore";
import Welcome from "./Welcome";
import { useNavigate } from "react-router-dom";
import Loading from "../../common/Loading";


const LoginPage = () => {
  const setToken = useStore((state) => state.setToken);
  const navigate = useNavigate();
  const [user, setUser] = useState({
    userIdentifier: "",
    passcode: "",
  });
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const handleLogin = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });

    // Reset errors as user types
    if (e.target.name === "userIdentifier") setEmailError("");
    if (e.target.name === "passcode") setPasswordError("");
  };

  const validateInputs = () => {
    let valid = true;

    if (!user.userIdentifier) {
      setEmailError("Email is required");
      valid = false;
    } else if (!/\S+@\S+\.\S+/.test(user.userIdentifier)) {
      setEmailError("Enter a valid email address");
      valid = false;
    }

    if (!user.passcode) {
      setPasswordError("Password is required");
      valid = false;
    }

    return valid;
  };

  const loginMutation = useMutation(authApi.fetch, {
    onSuccess: (data) => {
      if (data && data.accessToken) {
        setToken(data.accessToken);
        navigate('/home'); // Navigate to the dashboard on successful login
      }
    },
    onError: () => {
      alert("Invalid credentials");
      setUser({
        userIdentifier: "",
        passcode: "",
      });
    }
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateInputs()) {
      loginMutation.mutate(user); // Trigger the mutation with the user data
    }
  };

  return (
    <>
      <div className="h-screen w-screen flex flex-col md:flex-row font-sans bg-grayColor ">
        <div className="w-full md:w-2/5">
          <Welcome />
        </div>

        <div className="flex justify-center items-center w-full bg-grayColor flex-grow md:w-[60%] lg:w-[60%]">
          {/* Conditionally render the loader if the request is loading */}
          {loginMutation.isLoading ? (
            <Loading /> // Display loader component while loading
          ) : (
            <form onSubmit={handleSubmit} className="h-auto px-10 py-4 rounded-md">
              <div>
                <label htmlFor="email" className="text-xl font-bold pl-4">Email</label>
                <input
                  type="email"
                  name="userIdentifier"
                  value={user.userIdentifier}
                  className="w-full h-[60px] bg-white placeholder:text-black rounded-lg p-5 m-3 text-base"
                  onChange={handleLogin}
                />
                {emailError && <p style={{ color: "red" }}>{emailError}</p>}
              </div>
              <div>
                <label htmlFor="password" className="text-xl font-bold pl-4">Password</label>
                <input
                  type="password"
                  name="passcode"
                  value={user.passcode}
                  className="w-full h-[60px] placeholder:text-black bg-white rounded-lg p-5 m-3 mb-10"
                  onChange={handleLogin}
                />
                {passwordError && (
                  <p style={{ color: "red" }}>{passwordError}</p>
                )}
              </div>
              <button
                type="submit"
                className="w-full h-14 rounded-[36px] text-white text-xl mt-5 m-3 bg-customBlue mb-10 flex justify-center items-center"
              >
                Login
              </button>
            </form>
          )}
        </div>
      </div>
    </>
  );
};

export default LoginPage;
